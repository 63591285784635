import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useLanguage } from "../contexts/LanguageContext"
const CookieBar = () => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  const [data, setData] = useState(1)
  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedData = window.localStorage.getItem("myData")
      if (storedData) {
        setData(storedData)
      }
    }
  }, [])

  const handleSave = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("myData", "close")
      setData(data + 1)
    }
  }
  if (typeof window !== "undefined") {
    if (window.localStorage.getItem("myData") === "close") {
      return <></>
    }
  }
  return (
    <>
      {selectedLanguage === "EN" ? (
        <div className="cookie-bar">
          <div className="cookie-content">
            <div className="cookie-letters">
              <p className="cookie-text">
                The website www.osijek-koteks.hr uses cookies to improve
                functionality. By continuing to browse the site, you accept
                their use.
              </p>
            </div>

            <div className="button-container">
              <button className="cookie-button" onClick={handleSave}>
                Accept
              </button>
              <Link to="/pravila-privatnosti">
                <button className="cookie-button">Privacy policy »</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="cookie-bar">
          <div className="cookie-content">
            <div className="cookie-letters">
              <p className="cookie-text">
                Web stranica www.osijek-koteks.hr koristi kolačiće za
                poboljšanje funkcionalnosti. Nastavkom pregleda stranice
                prihvaćate njihovu upotrebu.
              </p>
            </div>

            <div className="button-container">
              <button className="cookie-button" onClick={handleSave}>
                Slažem se
              </button>
              <Link to="/pravila-privatnosti">
                <button className="cookie-button">Pravila privatnosti »</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieBar
