import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { FaTimes, FaArrowLeft } from "react-icons/fa"
import { useLanguage } from "../contexts/LanguageContext"
const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isVisible, setIsVisible] = React.useState(true)
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`}>
      <motion.button
        whileHover={{ scale: 1.2 }}
        transition={{ duration: 0.2 }}
        className="close-btn"
        onClick={toggleSidebar}
      >
        <FaTimes />
      </motion.button>
      <div>
        {selectedLanguage === "EN" ? (
          <div>
            <ul
              className={`${isOpen ? "sidebar-links" : ""} ${
                isVisible ? "" : "sidebar-invisible "
              }`}
            >
              <li>
                <Link to="/o-nama/posao">Employment</Link>
              </li>

              <li>
                <Link to="/usluge">Services</Link>
              </li>
              <li>
                <Link to="/certifikati">Certificates</Link>
              </li>
              <li>
                <Link to="/o-nama">About us</Link>
              </li>
              <li>
                <Link to="/podrska">Support</Link>
              </li>
              {/* <li>
                <Link to="/helpdesk">Helpdesk</Link>
              </li> */}
              <li>
                <Link to="/osnovni-podaci">Basic info</Link>
              </li>
              <li>
                <Link to="/reference">References</Link>
              </li>
              <li>
                <Link to="#" onClick={toggleVisibility}>
                  Contact
                </Link>
              </li>
            </ul>
            <ul
              className={`${isOpen ? "sidebar-links" : ""} ${
                !isVisible ? "" : "sidebar-invisible"
              }`}
            >
              <li>
                <Link to="#" onClick={toggleVisibility}>
                  {" "}
                  <FaArrowLeft />
                </Link>
              </li>
              <li>
                <Link to="/kontakt/uprava">Managment Board</Link>
              </li>
              <li>
                <Link to="/kontakt/financije">
                  Accounting and Finance Sector
                </Link>
              </li>
              <li>
                <Link to="/kontakt/pravniposlovi">
                  Department of Legal, Personnel and General Affairs
                </Link>
              </li>
              <li>
                <Link to="/kontakt/tehnicka">Operational Technical Sector</Link>
              </li>
              <li>
                <Link to="/kontakt/podugovaranje">
                  Service for contracting and procurement management
                </Link>
              </li>
              <li>
                <Link to="/kontakt/beton">Concrete production department</Link>
              </li>
              <li>
                <Link to="/kontakt/asfalt">
                  Asphalt production and installation department
                </Link>
              </li>
              <li>
                <Link to="/kontakt/transport">
                  Department of transport, construction machinery and workshops
                </Link>
              </li>
              <li>
                <Link to="/kontakt/geodeti">
                  Department of Geodetic Affairs
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <ul
              className={`${isOpen ? "sidebar-links" : ""} ${
                isVisible ? "" : "sidebar-invisible "
              }`}
            >
              <li>
                <Link to="/o-nama/posao">Zapošljavanje</Link>
              </li>

              <li>
                <Link to="/usluge">Usluge</Link>
              </li>
              <li>
                <Link to="/certifikati">Certifikati</Link>
              </li>
              <li>
                <Link to="/o-nama">O nama</Link>
              </li>
              <li>
                <Link to="/podrska">Podrška</Link>
              </li>
              {/* <li>
                <Link to="/helpdesk">Helpdesk</Link>
              </li> */}
              <li>
                <Link to="/osnovni-podaci">Osnovni podaci</Link>
              </li>
              <li>
                <Link to="/reference">Reference</Link>
              </li>
              <li>
                <Link to="#" onClick={toggleVisibility}>
                  Kontakt
                </Link>
              </li>
            </ul>
            <ul
              className={`${isOpen ? "sidebar-links" : ""} ${
                !isVisible ? "" : "sidebar-invisible"
              }`}
            >
              <li>
                <Link to="#" onClick={toggleVisibility}>
                  {" "}
                  <FaArrowLeft />
                </Link>
              </li>
              <li>
                <Link to="/kontakt/uprava">Uprava</Link>
              </li>
              <li>
                <Link to="/kontakt/financije">
                  Sektor Računovodstva i Financija
                </Link>
              </li>
              <li>
                <Link to="/kontakt/pravniposlovi">
                  Sektor Pravnih, kadrovskih i Općih Poslova
                </Link>
              </li>
              <li>
                <Link to="/kontakt/tehnicka">Operativno Tehnički Sektor</Link>
              </li>
              <li>
                <Link to="/kontakt/podugovaranje">
                  Služba za poslove ugovaranja i upravljanja nabavom
                </Link>
              </li>
              <li>
                <Link to="/kontakt/beton">Odjel proizvodnje betona</Link>
              </li>
              <li>
                <Link to="/kontakt/asfalt">
                  Odjel proizvodnje i ugradnje asfalta
                </Link>
              </li>
              <li>
                <Link to="/kontakt/transport">
                  Odjel transporta, građevinske mehanizacije i radione
                </Link>
              </li>
              <li>
                <Link to="/kontakt/geodeti">Odjel geodetskih poslova</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </aside>
  )
}

export default Sidebar
